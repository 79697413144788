import React, { useContext } from "react";
import Layout from "layout";
import { Context } from "redux/Store";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import { getUrlFromSlug } from "libs/services/utils";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/body/dry-skin/eucerin-advanced-repair-lotion-front.webp";
import backImage from "images/body/dry-skin/eucerin-advanced-repair-lotion-back.webp";
import frontZoomImage from "images/body/dry-skin/eucerin-advanced-repair-lotion-front-zoom.webp";
import backZoomImage from "images/body/dry-skin/eucerin-advanced-repair-lotion-back-zoom.webp";

// import img2 from "images/body/dry-skin/chart-eucerin-ar-lotion-vs-cerave.webp";
import img3 from "images/body/dry-skin/chart-eucerin-ar-lotion-vs-vehicle.webp";
import img4 from "images/body/dry-skin/chart-eucerin-ar-lotion-patient-survey.webp";
// import img5 from "images/callouts/section-cout-advanced-repair-resource.webp";
import img6 from "images/callouts/cout-footer-eczema.webp";
import img7 from "images/callouts/cout-footer-aquaphor.webp";
import img8 from "images/body/dry-skin/chart-eucerin-lotion-pediatric-tolerability.webp";

import "./eucerinadvancedrepairlotion.scss";

function DrySkinEucerinAdvancedRepairLotionPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);
  console.log('deploy test');
  
  useGoToAnchor();

  return (
    <Layout
      pageClass="page-body-dryskin page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Advanced Repair Lotion"
        pageDescription="Learn about Eucerin Advanced Repair Lotion"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#6ACAD5"
              categoryName="DRY SKIN"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Advanced Repair Lotion"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold teal-text sub-title">
                  Moisturize daily with superior hydration<sup>1</sup>
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Advanced Repair Lotion
                  </span>{" "}
                  helps strengthen skin’s moisture barrier and provides
                  clinically proven hydration suitable for daily use. The
                  lightweight formula includes purposeful ingredients that go
                  beyond ceramides.
                </p>
                <ul className="teal-bullets">
                  <li>
                    <span className="font-semi-bold">
                      Lightweight, daily hydration
                    </span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Superior hydration vs CeraVe<sup>®1</sup>
                    </span>
                  </li>
                  <li>
                    <span>
                      Formulated with natural moisturizing factors (NMFs) plus
                      ceramide NP
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold teal-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb">
                  <span className="font-semi-bold">
                    Natural moisturizing factors:
                  </span>{" "}
                  Urea, sodium lactate, and others
                </p>
                <ul className="teal-bullets">
                  <li>
                    <span>Improves water binding in stratum corneum</span>
                  </li>
                </ul>
                <p className="font-semi-bold">Ceramide NP</p>
                <ul className="teal-bullets">
                  <li>
                    <span>
                      Strengthens and supports moisture barrier; reduces water
                      loss
                    </span>
                  </li>
                </ul>
              </div>
            </ProductDetails>

            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold teal-text sub-title">
                    Formulated for daily use to hydrate sensitive skin
                  </p>
                  <ul className="checkmarks teal">
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                    <li>
                      <span>dye-free</span>
                    </li>
                    <li>
                      <span>paraben-free</span>
                    </li>
                    <li>
                      <span>non-comedogenic</span>
                    </li>
                  </ul>
                </div>
                <p className="references last">
                  <span className="font-bold">Reference: 1.</span> Superior
                  hydration vs CeraVe Moisturizing Lotion, measured by
                  Corneometer<sup>&reg;</sup> after 1 week of use (n=31).
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            {/* <AccordionContent
							accordionHeading='HYDRATION IMPROVEMENT'
							productThemeColor='eucerin-teal'
							id='hydrationimprovement'
						>
							<Row align='center' justify='center' direction='row'>
								<Col sm={12} md={8}>
									<img
										src={img2}
										className='accordion-chart'
										alt='Eucerin Advanced Repair Lotion Vs CeraVe Mositurizing Cream Chart'
									/>
									<p className='footnotes'>
										<span className='font-bold'>Study design:</span>{' '}
										Double-blind, bilateral, comparative study to assess the
										efficacy of Eucerin Advanced Repair Lotion vs CeraVe
										Moisturizing Cream. 10 days of use followed by 5 days of
										discontinuation (N=35).
									</p>
									<p className='references-symbols double'>
										**Statistically significant improvement compared to baseline
										(<span className='font-italic'>p&lt;</span>0.001).
									</p>
									<p className='references-symbols last'>
										<sup>&dagger;</sup>Statistically significant difference
										between treatments in favor of Eucerin (
										<span className='font-italic'>p&lt;</span>0.05).
									</p>
									<p className='references'>
										<span className='font-bold'>Reference: 1.</span> Data on
										file. Beiersdorf Inc.
									</p>
								</Col>
								<Col sm={12} md={4}>
									<div className='bordered-content'>
										Statistically significant <br className='show-desktop' />
										improvement in <br className='show-desktop' />
										hydration<sup>1</sup>
									</div>
								</Col>
							</Row>
						</AccordionContent> */}

            <AccordionContent
              accordionHeading="Vehicle formulation <br /> study"
              productThemeColor="eucerin-teal"
              id="vehicleformulationstudy"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Eucerin Advanced Repair Lotion Vs Vehicle Formulation Chart"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> In a
                    double-blind, vehicle-controlled study of subjects with very
                    dry skin, vehicle, vehicle plus, and Eucerin Advanced Repair
                    Lotion were applied on their inner forearms, according to a
                    randomization scheme twice daily for two weeks.
                  </p>
                  <p className="references-symbols">
                    <sup>a</sup>Significantly higher than untreated control.
                  </p>
                  <p className="references-symbols">
                    <sup>b</sup>Significantly higher than vehicle.
                  </p>
                  <p className="references-symbols last">
                    <sup>c</sup>Significantly higher than vehicle and vehicle
                    plus.
                  </p>
                  <p className="footnotes">
                    <span className="font-italic">p</span>&lt;0.05.
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Weber TM,
                    Kausch M, Rippke F, Schoelermann AM, Filbry AW. Treatment of
                    xerosis with a topical formulation containing glyceryl
                    glucoside, natural moisturizing factors, and ceramide.{" "}
                    <span className="font-italic">J Clin Aesthet Dermatol</span>
                    . 2012;5(8):29-39.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Double-blind study <br className="show-desktop" />
                    proves superior <br className="show-desktop" />
                    hydration<sup>2</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="TOLERABILITY IN CHILDREN"
              productThemeColor="eucerin-teal"
              id="tolerability"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img8}
                    className="accordion-chart"
                    alt="Tolerability study of pediatrics response from using Eucerin® Advanced Repair Lotion"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Children
                    ages 3 to 12 were randomized to Eucerin Advanced Repair
                    Lotion (n=33) or Cream (n=32) for once-daily, at-home use
                    for <nobr>14 days.</nobr> Clinical assessment at baseline
                    and Day 14, subjective assessment by parental questionnaire.
                  </p>
                  <p className="references">
                    Statistically significant agreement for all statements (
                    <span className="font-italic">p</span>&lt;0.0001).
                  </p>

                  <p className="references">
                    <span className="font-bold">Reference: 3.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Gentle enough <br className="show-desktop" />
                    for everyday use
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="SURVEY RESULTS"
              productThemeColor="eucerin-teal"
              id="surveyresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Patient survey stats after using Advanced Repair Lotion"
                  />
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Patients love the <br className="show-desktop" />
                    light, non-greasy feel <br className="show-desktop" />
                    of this lotion
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        {/* <section className='callouts-container gradient-bg section-support-resource light-blue'>
					<div className='inner-centered-container'>
						<Row>
							<Col sm={12} md={8} order={{ xs: 2, md: 1 }} className=''>
								<TypeC
									buttonText='DOWNLOAD HERE'
									buttonUrl={getUrlFromSlug(
										'eucerin-advanced-repair-formulation',
										state?.sanityData?.allResources
									)}
									className='center-v'
									isExternalLink={true}
									targetOpen='_blank'
								>
									<p className='reg-text-big'>
										Support your recommendation with this resource
									</p>
									<p className='reg-text'>
										See how Eucerin Advanced Repair Lotion significantly
										outperformed CeraVe Moisturizing Cream in hydrating dry
										skin.
									</p>
								</TypeC>
							</Col>
							<Col sm={12} md={4} order={{ xs: 1, md: 2 }} className=''>
								<img
									src={img5}
									alt='Eucerin Advanced Reapir Lotion Recommendation'
									className='cout-product-img'
								/>
							</Col>
						</Row>
					</div>
				</section> */}
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Eczema Regimen"
                  caption="Choose a proactive therapeutic<br class='show-desktop' /> regimen for eczema"
                  buttonText="Eczema Regimen"
                  buttonUrl="/body/eczema"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img7}
                  imgAlt="AQUAPHOR HEALING OINTMENT®"
                  caption="Provide clinically proven relief<br class='show-desktop' /> for dry, cracked skin"
                  buttonText="AQUAPHOR HEALING OINTMENT<sup>®</sup>"
                  buttonUrl="/body/damagedskin/aquaphordrycrackedskin"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DrySkinEucerinAdvancedRepairLotionPage;
